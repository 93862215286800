<template>
  <main id="profile">
    <section class="content">
      <section class="intro">
          <aside class="back" @click="back">tillbaka</aside>
          <h1 class="main">Studieprofil</h1>
      </section>
      <article class="floating">
        <h1>Studieprofil</h1>
        <p>Nedan visas en sammanställning av de test du gjort samt vad du markerat som intressant.</p>
        <profilegardner />
        <profileiceberg />
        <profilequiz />
        <tellmemore />
        <a href="#" class="btn danger" @click.prevent="reset">nollställ test</a>
        <router-link to="/test" class="btn">Fler test!</router-link>
      </article>
    </section>
  </main>
</template>

<script>

import profilegardner from '@/components/profiles/GardnerProfile';
import profileiceberg from '@/components/profiles/IcebergProfile';
import profilequiz from '@/components/profiles/QuizProfile';
import tellmemore from '@/components/profiles/TellMeMore';

export default {
  name: 'profile',
  components: {
      profilegardner,
      profileiceberg,
      profilequiz,
      tellmemore
  },
  methods: {
    back(){
      this.$emit('move', { transitionType: 'end', to: '/' });
    },
    reset(){
      let confirm = window.confirm('Detta rensar alla dina testresultat. Vill du det?')
      if(confirm){
        this.$store.commit('resetProfile');
      }
    }
  }
}
</script>

<style lang="scss">
@import '../scss/variables';

#profile {

  .intro {
    background: #000 !important;

    aside {
      position: absolute;
      top: 1rem;
      left: 1rem;
      padding: .25rem .5rem;
      border-radius: $radius;
      font-size: .8rem;
      border: 1px solid rgba($color: #FFF, $alpha: .4);
      color: rgba($color: #FFF, $alpha: .4);
      }

  }

  .floating {
    background: rgba($color: #eee, $alpha: 1) !important;

    h2 {
      margin: 2rem 0 1rem 0;
    }

    .btn {
      margin-top: 1rem;
    }

  }
}

</style>
