<template>
    <section class="tellmemore" v-if="subjects.length">
        <h2>Detta vill jag veta mer om</h2>
        <article class="test">
            <p v-for="(subject, index) in subjects" :key="index">{{ subject }}</p>
        </article>
    </section>
</template>

<script>
export default {
    name: 'profiletellmemore',
    computed: {
        subjects(){
            return this.$store.state.profile.tellMeMore;
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.tellmemore {

    .test {
        background: rgba($color: $darkgrey, $alpha: .1);
        border-radius: $radius;
        margin: 0 0 1rem 0;

        p {
            padding: .5rem;
            margin: 0;

            &:nth-child(2n) {
                background: rgba($color: $darkgrey, $alpha: .05);
            }
        
        }
    }
}
</style>
