<template>
    <section class="quiz">
        <h2>Valpåverkan</h2>
        <article class="test">
            <aside v-for="(item, index) in quiz" :key="index">
                <p class="potential" v-if="item.value > threshold">{{ item.text }}</p>
                <div class="barcontainer" v-if="item.value > threshold">
                    <div class="bar" :style="{ width: `${ (item.value / 5)*100 }%` }"></div>
                </div>
            </aside>
        </article>
    </section>
</template>

<script>
export default {
    name: 'profilequiz',
    data(){
        return {
            threshold: 2
        }
    },
    computed: {
        quiz(){
            return this.$store.getters.quiz.questions;
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.quiz {

    .test {
        background: rgba($color: $darkgrey, $alpha: .1);
        padding: .5rem;
        margin: 0 0 1rem 0;
        border-radius: $radius;

        .potential {
            font-size: .8rem;
            font-weight: 700;
            margin: .25rem 0;
        }

        .barcontainer {
            background: rgba($color: $darkgrey, $alpha: .1);
            border-radius: 99rem;
            padding: .125rem;
            margin-bottom: 1rem;
            overflow: hidden !important;

            .bar {
                height: .25rem;
                border-radius: $radius;
                background: $darkgrey;
            }
                }
    }
}
</style>
